<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <div class="search_center">
              <span>单据编号：</span>
              <el-input
                v-model="parameter.expenseNumber"
                @change="dataUpdate"
                class="ipt_width expense__width"
                placeholder="请输入单据编号"
                clearable
              >
              </el-input>
            </div>
            <div class="search_center">
              <span>商务名称：</span>
              <el-select
                v-model="parameter.businessManageId"
                @change="dataUpdate"
                clearable
                filterable
                placeholder="请输入或选择商务名称"
                class="ipt_width project_width"
              >
                <el-option
                  v-for="item in projectList"
                  :key="item.id"
                  :label="item.businessName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>

            <div class="search_center">
              <span>支出类型：</span>
              <Dictionary
                v-model="parameter.disbursementType"
                class="ipt_width"
                code="BUSINESS_DISBURSEMENT_TYPE"
                placeholder="请选择支出类型"
              />
            </div>

            <div class="search_center">
              <span>申请时间：</span>
              <el-date-picker
                class="date_width"
                v-model="createdDate"
                :pickerOptions="pickerOptions"
                type="daterange"
                range-separator="至"
                start-placeholder="申请日期（始）"
                end-placeholder="申请日期（末）"
              >
              </el-date-picker>
            </div>
            <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
              >搜索</el-button
            >
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          </div>
        </div>
        <div class="fr">
          <el-button type="primary" @click="drawer = true">报销流程</el-button>
          <el-button type="warning" icon="el-icon-plus" @click="add">新增</el-button>
        </div>
      </div>

      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-tabs v-model="parameter.applyType" class="fullScreenMainHeader">
            <el-tab-pane name="UNDETERMINED">
              <span slot="label">未提交 {{ `(${this.statistics.undetermind})` }}</span>
            </el-tab-pane>
            <el-tab-pane name="APPROVAL">
              <span slot="label">审批中 {{ `(${this.statistics.approval})` }}</span>
            </el-tab-pane>
            <el-tab-pane name="APPROVAL_REJECT">
              <span slot="label">不通过 {{ `(${this.statistics.approvalReject})` }}</span>
            </el-tab-pane>
            <el-tab-pane name="NOT_PAYED">
              <span slot="label">已审核 {{ `(${this.statistics.notPayed})` }}</span>
            </el-tab-pane>
            <el-tab-pane name="APPROVAL_PASS">
              <span slot="label">已支付 {{ `(${this.statistics.approvalPass})` }}</span>
            </el-tab-pane>
          </el-tabs>

          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :key="Math.random()"
            :data="tableData"
            :default-expand-all="true"
            tooltip-effect="dark"
            height="string"
            v-loading="loading"
          >
            <el-table-column align="center" type="index" label="序号"></el-table-column>
            <el-table-column
              align="center"
              prop="expenseNumber"
              width="160"
              label="单据编号"
            ></el-table-column>
            <!-- <el-table-column align="center" prop="userName" label="姓名"></el-table-column>
            <el-table-column align="center" prop="deptName" label="部门"></el-table-column> -->
            <!-- <el-table-column align="center" prop="deptName" label="岗位"></el-table-column>                         -->
            <el-table-column
              align="center"
              prop="businessName"
              sortable
              min-width="320"
              :show-overflow-tooltip="false"
              label="商务名称"
            ></el-table-column>
            <!-- <el-table-column align="center" prop="projectCostNumber" label="商务单据编号"></el-table-column> -->
            <el-table-column align="center" width="110" sortable prop="sumCost" label="报销费用">
              <template slot-scope="scope">
                {{ scope.row.sumCost | applyAmount }}
              </template></el-table-column
            >

            <!-- <Dictionary :code="PROJECT_COST_STATUS" /> -->
            <!-- <el-table-column align="center" prop="auditUserName" label="审批通过人"></el-table-column> -->
            <!-- <el-table-column
              align="center"
              v-if="parameter.status != '0'"
              prop="auditDate"
              label="审批时间"
            >
              <template slot-scope="scope">
                {{
                  scope.row.auditDate
                    ? new Date(scope.row.auditDate).format('yyyy-MM-dd HH:mm:ss')
                    : ''
                }}
              </template>
            </el-table-column> -->
            <el-table-column align="center" width="110" sortable prop="taskDate" label="填报时间">
              <template slot-scope="scope">
                {{ scope.row.taskDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              sortable
              min-width="120"
              prop="expenseMode"
              label="报销方式"
            >
              <template slot-scope="scope">
                {{ scope.row.expenseMode | dict(dictData.expenseMode) }}
              </template></el-table-column
            >
            <el-table-column
              align="center"
              sortable
              width="180"
              prop="applyExpenseType"
              label="付款方式"
            >
              <template slot-scope="scope">
                {{ scope.row.applyExpenseType | dict(applyExpenseType) }}
              </template></el-table-column
            >
            <el-table-column
              align="center"
              sortable
              min-width="200"
              prop="disbursementType"
              label="支出类型"
            >
              <template slot-scope="scope">
                {{ scope.row.disbursementType | dict(dictData.disbursementType) }}
              </template></el-table-column
            >
            <el-table-column
              align="center"
              sortable
              prop="statusName"
              label="流程进度"
              min-width="150"
            >
            </el-table-column>
            <el-table-column prop="title" align="center" fixed="right" width="120" label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="text"
                  class="text_Details_Bgc"
                  @click.stop="detail(scope.row)"
                  v-if="scope.row.status != 0"
                  >详情</el-button
                >
                <el-button
                  size="mini"
                  type="text"
                  class="text_Edit_Bgc"
                  @click.stop="edit(scope.row)"
                  v-if="scope.row.status == 0 || scope.row.status == 5"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="text"
                  class="text_Remove_Bgc"
                  @click.stop="del(scope.row)"
                  v-if="
                    scope.row.status == 0 &&
                    scope.row.expenseMode != 'REGISTRATION_EXPENDITURE_DOCUMENT'
                  "
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>

    <el-dialog width="75%" title="商务报销流程图" :visible.sync="drawer">
      <div style="padding: 5px">
        <img src="@/assets/商务报销流程图.png" alt="商务报销流程图" width="100%" />
      </div>
    </el-dialog>

    <Edit
      v-if="showEditDialog"
      :getTitle="getTitle"
      :isShow.sync="showEditDialog"
      :options="form"
    ></Edit>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Edit: () => import('./Edit.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
  },
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        applyType: 'UNDETERMINED',
        disbursementType: null,
        businessManageId: null,
        expenseNumber: null, //申请编号
        startDate: null, //查询开始时间
        endDate: null, //查询结束时间
      },
      dictData: {
        expenseMode: [], //报销方式
        disbursementType: [], //支出类型
      },
      pageSize: 0,
      form: {},
      tableData: [],
      loading: false,
      showEditDialog: false,
      projectList: [], // 商务数据
      drawer: false,
      createdDate: [],
      statistics: {
        undetermind: 0,
        approval: 0,
        approvalReject: 0,
        notPayed: 0,
        approvalPass: 0,
      },
      getTitle: '',
      pickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.createdDate, 0, v.minDate.getTime())
            this.$set(this.createdDate, 1, '')
          }
        },
      },
      applyExpenseType: [], //付款方式字典
    }
  },
  watch: {
    'parameter.applyType': {
      // immediate:true,//初始化立即执行
      // deep: true,//对象深度监测
      handler: function (newVal, oldVal) {
        this.parameter.pageNow = 1
        this.getData()
      },
    },
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getData()
        }
      },
    },
    createdDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.createdDate = []
        }
      },
    },
  },

  provide() {
    return {
      re: this.re,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getProjectList() /** 加载 "商务" 数据 **/
    this.getData()
    this.$api.dict
      .listSysDictData('EXPENSE_MODE', true)
      .then(res => {
        this.dictData.expenseMode = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('BUSINESS_DISBURSEMENT_TYPE', true)
      .then(res => {
        this.dictData.disbursementType = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('APPLY_EXPENSE_TYPE', true)
      .then(res => {
        this.applyExpenseType = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  filters: {
    applyAmount(n) {
      if (!n) return
      return Number(n).toLocaleString()
    },
  },
  methods: {
    getApplyStatistics() {
      this.$api.businessExpense
        .getApplyStatistics()
        .then(res => {
          if (res.data) {
            res.data.forEach(v => {
              if (v.applyType == 'UNDETERMINED') {
                this.statistics.undetermind = v.sumQuantity
              } else if (v.applyType == 'APPROVAL') {
                this.statistics.approval = v.sumQuantity
              } else if (v.applyType == 'APPROVAL_REJECT') {
                this.statistics.approvalReject = v.sumQuantity
              } else if (v.applyType == 'NOT_PAYED') {
                this.statistics.notPayed = v.sumQuantity
              } else if (v.applyType == 'APPROVAL_PASS') {
                this.statistics.approvalPass = v.sumQuantity
              }
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getData() {
      if (this.createdDate && this.createdDate != []) {
        this.parameter.startDate = new Date(this.createdDate[0]).getTime()
        this.parameter.endDate = new Date(this.createdDate[1]).getTime()
      } else if (this.createdDate == null) {
        this.parameter.startDate = null
        this.parameter.endDate = null
      }

      this.loading = true
      this.$api.businessExpense
        .getApplyForList(this.parameter)
        .then(res => {
          this.loading = false
          this.tableData = res.data ? (res.data.records ? res.data.records : []) : []
          this.parameter.total = res.data ? (res.data.total ? res.data.total : 0) : 0
          this.tableData.forEach(item => {
            if (item.disbursementType == 'UNDEFINED_RATIFICATION_IMPLEMENT') {
              item.businessName = item.businessContent
            }
          })
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
      this.getApplyStatistics()
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    add() {
      this.form = {}
      this.form.list_s = 0
      this.form.export = 0
      this.getTitle = '新增报销单'
      this.showEditDialog = true
    },
    detail(row) {
      this.form = row.deepClone()
      if (row.status == 100) {
        // 点击已完成审核的详情
        this.form.list_s = 3
      } else {
        this.form.list_s = 2
      }
      if (this.parameter.applyType == 'NOT_PAYED') {
        this.form.export = 1
      } else {
        this.form.export = 0
      }
      this.getTitle = '查看报销单详情'
      this.showEditDialog = true
    },
    edit(row) {
      row.list_s = 1
      this.form = row.deepClone()

      this.getTitle = '编辑报销单'
      this.showEditDialog = true
    },
    re() {
      this.form = {}
      this.getData()
      this.showEditDialog = false
    },

    //全局分页保存
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }

      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    /**  点击 "重置" 按钮 **/
    handleReset() {
      this.parameter.disbursementType = null
      this.parameter.businessManageId = null
      this.parameter.startDate = null
      this.parameter.endDate = null
      this.parameter.expenseNumber = null
      this.createdDate = []
      this.parameter.pageNow = 1
      this.getData() /** 加载  数据 **/
    },
    /** 加载 "商务" 数据 **/
    getProjectList() {
      this.$api.businessExpense
        .getBusinessNameList()
        .then(res => {
          this.projectList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    // //获取用户可以申请报销得商务名单
    // getbusinessNameList() {
    //     this.$api.businessExpense.getBusinessNameList().then(res => {
    //         this.businessNameList = res.data;
    //     }).catch(err => {
    //         console.log(err);
    //     })
    // },

    /** 删除 **/
    del(row) {
      this.$confirm('确认删除该条报销单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$api.businessExpense
            .deleteExpense({ id: row.id })
            .then(res => {
              this.$message({
                type: 'success',
                message: '删除成功',
              })
              this.getData()
            })
            .catch(err => {
              console.log('错误：', err)
            })
        })
        .catch(() => {
          //取消
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },

    dataUpdate() {
      this.$forceUpdate()
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
</style>
